import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-strip',
  templateUrl: './info-strip.component.html',
  styleUrls: ['./info-strip.component.scss'],
})
export class InfoStripComponent {
  @Input() message = '';
  @Input() class: 'warning' | 'error' | 'default' = 'default';
}
