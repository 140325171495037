import { createFeatureSelector, createSelector } from '@ngrx/store';
import { homeKey, HomeState } from '@app/store/home/home.reducer';

export const selectHomeState = createFeatureSelector<HomeState>(homeKey);

export const selectIsSidebarVisible = createSelector(
  selectHomeState,
  (it: HomeState) => it.geoSidebarVisible
);
export const selectIsOnBoarding = createSelector(
  selectHomeState,
  (it: HomeState) => it.isOnBoardingStage
);

export const selectIsLoggedInByPlatformAdmin = createSelector(
  selectHomeState,
  (it: HomeState) => it.isLoggedInByPlatformAdmin
);
