import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  FormFieldSettings,
  FormFieldTypeEnum,
  IImageUploaderSettings,
} from '@app/shared/components/form-builder/form-builder.interface';

@Component({
  selector: 'app-form-renderer',
  templateUrl: './form-renderer.component.html',
  styleUrls: ['./form-renderer.component.scss'],
})
export class FormRendererComponent implements OnInit {
  @Input() formFields: FormFieldSettings[] = [];
  @Input() hideSubmitButton: boolean = false;
  @Input() readonly: boolean = false;
  @Output() formSubmitted: EventEmitter<unknown> = new EventEmitter();
  @Output() formChanged: EventEmitter<unknown> = new EventEmitter();

  form: FormGroup = this.fb.group({});
  FormFieldType = FormFieldTypeEnum;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();

    this.form.valueChanges.subscribe(() => {
      this.formFields.forEach((field: FormFieldSettings) => {
        this.setHiddenFields(field);
      });

      this.formChanged.emit();
    });

    if (this.readonly) {
      this.form.disable();
    }
  }

  private initForm() {
    this.formFields.forEach((field: FormFieldSettings) => {
      this.initFormControl(field);
      this.setHiddenFields(field);
    });
  }

  private initFormControl(field: FormFieldSettings): void {
    this.form.addControl(
      field.controlName,
      this.fb.control(
        {
          value: field.defaultValue || '',
          disabled: field.disabled || false,
        },
        field.validators
      ),
      { emitEvent: false }
    );
  }

  private setHiddenFields(field: FormFieldSettings) {
    if (
      field.isHiddenCondition &&
      !this.form.controls[field.isHiddenCondition]?.value
    ) {
      field.isHidden = true;
      this.form.removeControl(field.controlName, { emitEvent: false });
    } else {
      this.initFormControl(field);
      field.isHidden = false;
    }
  }

  chooseImage(imageUrl: string, field: IImageUploaderSettings) {
    field.value = imageUrl;
    this.form.controls[field.controlName].patchValue(imageUrl);
  }

  deleteImage(field: IImageUploaderSettings) {
    this.form.controls[field.controlName].patchValue('');
  }

  submitForm(): void {
    this.form.markAllAsTouched();
    this.formSubmitted.emit(this.form.getRawValue());
  }
}
