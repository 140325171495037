import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirst',
})
export class CapitalizeFirstPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    const val = value.toLowerCase();
    return val.charAt(0).toUpperCase() + val.slice(1);
  }
}
