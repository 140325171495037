import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as BrandsReducer from './brands-reducer';
import { BrandsEffects } from '@app/shared/store/brands/brands-effects';

@NgModule({
  imports: [
    StoreModule.forFeature(
      BrandsReducer.brandsKey,
      BrandsReducer.BrandsReducer
    ),
    EffectsModule.forFeature([BrandsEffects]),
  ],
})
export class BrandsStoreModule {}
