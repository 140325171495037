import { Pipe, PipeTransform } from '@angular/core';
import { NumberTranslatePipe } from '@app/shared/pipes/number-translate.pipe';

@Pipe({
  name: 'ratingRange',
})
export class RatingRangePipe implements PipeTransform {
  constructor(private numberTranslatePipe: NumberTranslatePipe) {}
  transform(min: number, max: number): string {
    if (min < 0 || max > 5 || min > max) {
      return 'Invalid rating range';
    }

    if (min === max) {
      return `<span class="notranslate">${this.numberTranslatePipe.transform(min)} </span> <span>stars</span>`;
    }

    if (min === 0 && max === 5) {
      return `<span>Any rating</span>`;
    }

    if (min === 0) {
      return `<span>Below</span> <span class="notranslate"> ${this.numberTranslatePipe.transform(max)} </span>  <span>stars</span>`;
    }

    if (max === 5) {
      return `<span class="notranslate">${this.numberTranslatePipe.transform(min)} </span><span>stars and above</span>`;
    }

    return `<span class="notranslate">${this.numberTranslatePipe.transform(min)} </span> <span>to</span><span class="notranslate"> ${this.numberTranslatePipe.transform(max)} </span> <span>stars</span>`;
  }
}
