import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import {
  MatSelect,
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import { JsonPipe, NgForOf } from '@angular/common';
export interface MultiSelectComponentOption {
  label: string;
  value: string;
}
@Component({
  selector: 'app-multi-select',
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    ReactiveFormsModule,
    JsonPipe,
  ],
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.scss',
})
export class MultiSelectComponent implements OnInit, OnChanges {
  @ViewChild('multiSelect') multiSelect!: MatSelect;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() selectedOptions: MultiSelectComponentOption[] = [];
  @Input() selectOptions: MultiSelectComponentOption[] = [];
  @Output() selectChange: EventEmitter<MultiSelectComponentOption[]> =
    new EventEmitter<MultiSelectComponentOption[]>();
  form: FormGroup = {} as FormGroup;

  constructor(private fb: FormBuilder) {}
  ngOnInit() {
    this.form = this.fb.group({
      selectedOptions: new FormControl(
        this.selectedOptions.map((it) => it.value)
      ),
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedOptions']) {
      if (
        this.form &&
        this.form.controls &&
        this.form.controls['selectedOptions']
      ) {
        const newSelectedOptions = this.selectedOptions.map((it) => it.value);
        this.form.controls['selectedOptions'].patchValue(newSelectedOptions);
      }
    }
  }
  selectItems($event: MatSelectChange) {
    this.form.controls['selectedOptions'].patchValue('', {
      onlySelf: true,
      emitEvent: false,
    });
    const newValue = this.selectOptions.filter((it) =>
      $event.value.includes(it.value)
    );
    this.selectChange.emit(newValue);
  }

  removeSelectedOption(value: string) {
    const tempArray = this.selectedOptions.filter(
      (item: MultiSelectComponentOption) => item.value !== value
    );
    this.selectChange.emit(tempArray);
  }
}
