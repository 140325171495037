import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stars-review',
  templateUrl: './stars-review.component.html',
  styleUrls: ['./stars-review.component.scss'],
})
export class StarsReviewComponent {
  @Input() rating = 1;
}
