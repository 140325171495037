<div
  class="snack-bar-container"
  [ngClass]="{ 'with-action-button': !!data.button }"
>
  <div class="snack-bar-wrapper">
    <div class="snack-bar-content-wrapper">
      <div class="snack-bar-icon" *ngIf="data.type !== 'basic'">
        <mat-icon
          [ngClass]="data.type"
          class="mat-icon-rtl-mirror"
          [svgIcon]="
            data.type === 'success'
              ? 'circle-check'
              : data.type === 'error'
                ? 'circle-exclamation'
                : ''
          "
        >
        </mat-icon>
      </div>
      <div class="snack-bar-content">
        <div class="title" *ngIf="data.title" [innerHtml]="data.title"></div>
        <div *ngIf="data.message" [innerHtml]="data.message"></div>
      </div>
    </div>
    <button
      class="text-button action-button"
      *ngIf="data.button"
      (click)="handleButtonActionClick()"
    >
      <span class="mx-2">{{ data.button.label }}</span>
      <span class="material-icons" *ngIf="data.button.icon">{{
        data.button.icon
      }}</span>
    </button>

    <div class="snack-bar-close" *ngIf="data.dismissible && !data.button">
      <mat-icon (click)="sbRef.dismiss()">close</mat-icon>
    </div>
  </div>
</div>
