import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-page-subheader',
  templateUrl: './page-sub-header.component.html',
  styleUrls: ['./page-sub-header.component.scss'],
})
export class PageSubHeaderComponent {
  @Input() icon!: string;
  @Input() title!: string;
  @Input() subTitle = '';
  @Input() isTitleTranslatable = true;
  @Input() buttonLabel = '';
  @Input() showPlusIcon = true;
  @Input() buttonIcon = '';
  @Input() toolTip = '';
  @Input() isButtonDisabled = false;
  @Input() activateBack = false;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() backClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() titleClick: EventEmitter<void> = new EventEmitter<void>();

  buttonClicked(): void {
    this.buttonClick.emit();
  }

  backClicked(): void {
    this.backClick.emit();
  }
  titleClicked(): void {
    this.titleClick.emit();
  }
}
