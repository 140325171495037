import { createReducer, on } from '@ngrx/store';
import {
  disconnectGoogle,
  fetchIndustries,
  fetchIndustriesFailure,
  fetchIndustriesSuccess,
  fetchLocations,
  fetchLocationsFailure,
  fetchLocationsSuccess,
  fetchMyBrands,
  fetchMyBrandsFailure,
  fetchMyBrandsSuccess,
  fetchMyCompany,
  fetchMyCompanyFailure,
  fetchMyCompanySuccess,
  resetStore,
  setGoogleIsConnected,
  setLocationsCount,
} from '@app/modules/onboarding/store/actions';
import { GoogleConnectLocation } from '@app/shared/models/google-integration-location';
import { Industry } from '@app/shared/models/industry.interface';
import { Company } from '@app/shared/models/company';
import { Brand } from '@app/shared/models/brand';

export const onBoardingKey = 'onBoarding';

export interface OnBoardingState {
  isGoogleConnected: boolean;
  googleLocations: GoogleConnectLocation[];
  industries: Industry[];
  company: Company;
  loading: boolean;
  error: string;
  brands: Brand[];
  locationsCount: number;
}

export const initialState: OnBoardingState = {
  isGoogleConnected: false,
  googleLocations: [],
  industries: [],
  company: {} as Company,
  loading: false,
  error: '',
  brands: [],
  locationsCount: 1,
};

export const onBoardingReducer = createReducer(
  initialState,
  on(
    setGoogleIsConnected,
    disconnectGoogle,
    (state: OnBoardingState, { connected }) => ({
      ...state,
      isGoogleConnected: connected,
    })
  ),
  on(fetchLocations, (state: OnBoardingState) => ({
    ...state,
    loading: true,
    error: initialState.error,
  })),
  on(fetchLocationsSuccess, (state: OnBoardingState, { locations }) => ({
    ...state,
    isGoogleConnected: true,
    googleLocations: locations,
    loading: false,
  })),
  on(fetchLocationsFailure, (state: OnBoardingState, { error }) => ({
    ...initialState,
    error: error,
  })),
  on(fetchIndustries, (state: OnBoardingState) => ({
    ...state,
    loading: true,
    error: initialState.error,
  })),
  on(fetchIndustriesSuccess, (state: OnBoardingState, { industries }) => ({
    ...state,
    industries: industries,
    loading: false,
  })),
  on(fetchIndustriesFailure, (state: OnBoardingState, { error }) => ({
    ...initialState,
    error: error,
  })),
  on(fetchMyCompany, (state: OnBoardingState) => ({
    ...state,
    loading: true,
    error: initialState.error,
  })),
  on(fetchMyCompanySuccess, (state: OnBoardingState, { company }) => ({
    ...state,
    company: company,
    loading: false,
  })),
  on(fetchMyCompanyFailure, (state: OnBoardingState, { error }) => ({
    ...initialState,
    error: error,
  })),
  on(fetchMyBrands, (state: OnBoardingState) => ({
    ...state,
    loading: true,
    error: initialState.error,
  })),
  on(fetchMyBrandsSuccess, (state: OnBoardingState, { brands }) => ({
    ...state,
    brands: brands,
    loading: false,
  })),
  on(fetchMyBrandsFailure, (state: OnBoardingState, { error }) => ({
    ...initialState,
    error: error,
  })),
  on(resetStore, () => ({
    ...initialState,
  })),
  on(setLocationsCount, (state: OnBoardingState, { locationsCount }) => ({
    ...state,
    locationsCount,
  }))
);
