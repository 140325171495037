import { createAction, props } from '@ngrx/store';
import { ActionCallBack } from '@app/shared/models/action-callback.enum';
import { LoginUser } from '@app/shared/models/user/login.user.model';
import { User } from '@app/shared/models/user/user';
import { PlanSubscription } from '@app/shared/models/user/plan-subscription';

export const signUp = createAction(
  '[AUTH] sign up',
  props<{ email: string; password: string }>()
);
export const signUpSuccess = createAction(
  '[AUTH] sign up success',
  props<{ email: string }>()
);
export const signUpFailure = createAction(
  '[AUTH] sign up failure',
  props<{ error: string }>()
);

export const signIn = createAction(
  '[AUTH] sign in',
  props<{ email: string; password: string }>()
);

export const signInSuccess = createAction(
  '[AUTH] sign in success',
  props<{ authUserData: LoginUser }>()
);

export const signInFailure = createAction(
  '[AUTH] sign in failure',
  props<{ error: string }>()
);

export const requestResetPassword = createAction(
  '[AUTH] request reset password',
  props<{ email: string; callBack: ActionCallBack }>()
);

export const requestResetPasswordSuccess = createAction(
  '[AUTH] request reset password success',
  props<{ email: string; callBack: ActionCallBack }>()
);

export const requestResetPasswordFailure = createAction(
  '[AUTH] request reset password failure',
  props<{ error: string; callBack: ActionCallBack }>()
);

export const resetAuthStore = createAction('[AUTH] reset Auth Store');

export const signOut = createAction('[AUTH] sign out');

export const requestResendEmailForVerification = createAction(
  '[AUTH] request resend email for account-verification'
);
export const requestResendEmailForVerificationSuccess = createAction(
  '[AUTH]  request resend email for account-verification success',
  props<{ email?: string }>()
);

export const requestResendEmailForVerificationFailure = createAction(
  '[AUTH]  request resend email for account-verification failure',
  props<{ error: string }>()
);
export const setToken = createAction(
  '[AUTH] Set Token',
  props<{ refresh: boolean }>()
);
export const setTokenSuccess = createAction(
  '[AUTH] Set Token Success',
  props<{ token: string; expirationTime: Date }>()
);
export const getMe = createAction('[AUTH] Get Me');
export const getMeSuccess = createAction(
  '[AUTH] Get Me success',
  props<{ user: User }>()
);
export const getMeFailure = createAction(
  '[AUTH] Get Me failure',
  props<{ error: string }>()
);

export const getSubscription = createAction('[SUBSCRIPTION] Get Subscription');
export const getSubscriptionFailure = createAction(
  '[SUBSCRIPTION] Get Subscription Failure',
  props<{ error: string }>()
);
export const getSubscriptionSuccess = createAction(
  '[SUBSCRIPTION] Get Subscription Success',
  props<{ subscription: PlanSubscription }>()
);
