import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { HomeState } from '@app/store/home/home.reducer';
import {
  selectIsLoggedInByPlatformAdmin,
  selectIsOnBoarding,
  selectIsSidebarVisible,
} from '@app/store/home/home.selector';
import { setIsLoggedInByPlatformAdmin } from '@app/store/home/home.actions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HomeFacade {
  constructor(private store: Store<HomeState>) {}

  selectIsSidebarVisible$ = this.store.pipe(select(selectIsSidebarVisible));
  selectIsOnBoarding$ = this.store.pipe(select(selectIsOnBoarding));
  selectIsLoggedInByPlatformAdmin$: Observable<boolean> = this.store.pipe(
    select(selectIsLoggedInByPlatformAdmin)
  );

  setIsloggedInByPlatformAdmin(isLoggedInByPlatformAdmin: boolean) {
    this.store.dispatch(
      setIsLoggedInByPlatformAdmin({
        isLoggedInByPlatformAdmin: isLoggedInByPlatformAdmin,
      })
    );
  }
}
