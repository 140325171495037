import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClarityService {
  setEmailForClarity(userEmail: string, companyName: string) {
    window.dataLayer.push({
      event: 'setEmailForClarity',
      userEmail: userEmail,
      companyName: companyName,
    });
  }
}
