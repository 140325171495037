<section class="menu-preview">
  <h5 class="title">{{ navItem.title }}</h5>
  <div class="navigation-item-children">
    <ng-container *ngFor="let item of navItem.children; trackBy: trackByFn">
      <app-navigation-item
        *ngIf="item.type === navigationType.item"
        [item]="item"
      ></app-navigation-item>
      <app-navigation-collapsable-item
        *ngIf="item.type === navigationType.collapsable"
        [item]="item"
      ></app-navigation-collapsable-item>
    </ng-container>
  </div>
</section>
