import { Component, Input } from '@angular/core';
import {
  NavigationItem,
  NavigationType,
} from '@app/core/components/navigation/navigation.types';

@Component({
  selector: 'app-menu-preview',
  templateUrl: './menu-preview.component.html',
  styleUrls: ['./menu-preview.component.scss'],
})
export class MenuPreviewComponent {
  navigationType: typeof NavigationType = NavigationType;
  @Input() navItem!: NavigationItem;

  trackByFn(index: number, item: NavigationItem): string {
    return item.id ?? '';
  }
}
