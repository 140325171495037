import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  userSettingsKey,
  UserSettingsState,
} from '@app/shared/store/user-settings/user-settings.reducer';

export const selectUserSettingsState =
  createFeatureSelector<UserSettingsState>(userSettingsKey);

export const selectLanguage = createSelector(
  selectUserSettingsState,
  (it: UserSettingsState) => it.language
);

export const selectLanguages = createSelector(
  selectUserSettingsState,
  (it: UserSettingsState) => it.languages
);
export const selectTheme = createSelector(
  selectUserSettingsState,
  (it: UserSettingsState) => it.theme
);
