import { createAction, props } from '@ngrx/store';
import { Language } from '@app/shared/models/transifex';

export const setLanguage = createAction(
  'set Language',
  props<{ language: string }>()
);

export const setLanguages = createAction(
  'set Languages',
  props<{ languages: Language[] }>()
);

export const setTheme = createAction('set Theme');
