import { createAction, props } from '@ngrx/store';
import { GoogleConnectLocation } from '@app/shared/models/google-integration-location';
import { Industry } from '@app/shared/models/industry.interface';
import { Company } from '@app/shared/models/company';
import { Brand } from '@app/shared/models/brand';

export const fetchLocations = createAction(
  'fetch Locations',
  props<{ brandId: string }>()
);
export const fetchLocationsSuccess = createAction(
  'fetch Locations Success',
  props<{ locations: Array<GoogleConnectLocation> }>()
);
export const fetchLocationsFailure = createAction(
  'fetch Locations Failure',
  props<{ error: string }>()
);

export const fetchIndustries = createAction('fetch Industries');
export const fetchIndustriesSuccess = createAction(
  'fetch Industries Success',
  props<{ industries: Array<Industry> }>()
);
export const fetchIndustriesFailure = createAction(
  'fetch Industries Failure',
  props<{ error: string }>()
);

export const fetchMyCompany = createAction('fetch MyCompanies');
export const fetchMyCompanySuccess = createAction(
  'fetch MyCompanies Success',
  props<{ company: Company }>()
);
export const fetchMyCompanyFailure = createAction(
  'fetch MyCompanies Failure',
  props<{ error: string }>()
);

export const disconnectGoogle = createAction(
  'disconnect google',
  props<{ connected: boolean }>()
);
export const setGoogleIsConnected = createAction(
  'Set Google Is Connected',
  props<{ connected: boolean }>()
);

export const resetStore = createAction('reset onboarding brands');

export const fetchMyBrands = createAction('fetch MyBrands');
export const fetchMyBrandsSuccess = createAction(
  'fetch MyBrands Success',
  props<{ brands: Array<Brand> }>()
);
export const fetchMyBrandsFailure = createAction(
  'fetch MyBrands Failure',
  props<{ error: string }>()
);

export const setLocationsCount = createAction(
  'Set Onboarding Locations Count',
  props<{ locationsCount: number }>()
);
