import { select, Store } from '@ngrx/store';
import {
  selectBrandById,
  selectBrands,
  selectSelectedBrand,
  selectSelectedBrandSocialIntegrationStatus,
} from '@app/shared/store/brands/brands-selector';
import {
  fetchBrands,
  resetBrandSocialIntegrationStatus,
  setSelectedBrand,
  setSelectedBrandSocialIntegrationStatus,
} from '@app/shared/store/brands/brands-actions';
import { BrandsState } from '@app/shared/store/brands/brands-reducer';
import { Brand } from '@app/shared/models/brand';
import { Injectable } from '@angular/core';
import { BrandIntegrationTypes } from '@app/shared/models/integration';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrandsFacade {
  constructor(private store: Store<BrandsState>) {}

  selectSelectedBrand$ = this.store.pipe(select(selectSelectedBrand));
  selectSelectedBrandSocialIntegrationStatus$ = this.store.pipe(
    select(selectSelectedBrandSocialIntegrationStatus)
  );
  selectBrands$ = this.store.pipe(select(selectBrands));

  selectBrandById$(id: string): Observable<Brand> {
    return this.store.select(selectBrandById(id));
  }
  fetchBrands(): void {
    this.store.dispatch(fetchBrands());
  }

  selectBrand(brand: Brand) {
    this.store.dispatch(setSelectedBrand({ brand: brand }));
  }

  setSelectedBrandSocialIntegrationStatus({
    data,
    key,
  }: {
    data: { [key: string]: string | boolean | null };
    key: BrandIntegrationTypes;
  }) {
    this.store.dispatch(
      setSelectedBrandSocialIntegrationStatus({ data: data, key: key })
    );
  }
  resetBrandSocialIntegrationStatus() {
    this.store.dispatch(resetBrandSocialIntegrationStatus());
  }
}
