import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import {
  LoggerModule,
  NGXLogger,
  NgxLoggerLevel,
  TOKEN_LOGGER_CONFIG,
} from 'ngx-logger';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { environment } from 'src/environments/environment';
import { Router, TitleStrategy } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
// i18n imports
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const env = JSON.parse(JSON.stringify(environment));
// NGRX
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, reducers } from '@app/store/home/reducers';
import { AngularFireModule } from '@angular/fire/compat';
import {
  ApplicationHttpClient,
  applicationHttpClientCreator,
} from '@app/helpers/custom-http-client';
import { HomeEffects } from '@app/store/home/home.effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { FireAuthHeaderInterceptor } from '@app/shared/interceptors/fire-auth-header.interceptor';
import { CustomPageTitleStrategy } from '@app/core/CustomPageTitleStrategy';
// Define your logger config object
const loggerConfig = {
  serverLoggingUrl: '/api/logs',
  level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.DEBUG,
  serverLogLevel: NgxLoggerLevel.ERROR,
};

// i18n factory function
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/locale/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    LoggerModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: false,
    }),
    StoreDevtoolsModule.instrument({}),
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase, 'clientConfig'),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([HomeEffects]),
    StoreDevtoolsModule.instrument(),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    NGXLogger,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FireAuthHeaderInterceptor,
      multi: true,
    },
    { provide: TOKEN_LOGGER_CONFIG, useValue: loggerConfig },
    {
      // Use the 'fill' appearance on Angular Material form fields by default
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        subscriptSizing: 'dynamic',
      },
    },
    // sentry config
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: environment.production,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: ApplicationHttpClient,
      useFactory: applicationHttpClientCreator,
      deps: [HttpClient],
    },
    { provide: TitleStrategy, useClass: CustomPageTitleStrategy }, // custom title
    { provide: 'googleTagManagerId', useValue: env.gtm },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
