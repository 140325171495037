import { AfterViewInit, Component, Input } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'app-health-check-card',
  templateUrl: './health-check-card.component.html',
  styleUrls: ['./health-check-card.component.scss'],
})
export class HealthCheckCardComponent implements AfterViewInit {
  @Input() completionScore: number = 0;
  translate(value: number) {
    return value?.toString &&
      typeof value !== 'object' &&
      !Number.isNaN(parseFloat(value.toString()))
      ? moment.unix(parseFloat(value.toString())).format(value.toString())
      : '';
  }
  ngAfterViewInit(): void {
    const meters = document.querySelectorAll(
      'svg[data-value] .meter'
    ) as NodeListOf<SVGGeometryElement>;
    meters.forEach((path: SVGGeometryElement) => {
      const length = path.getTotalLength();
      const trimmedNumber = Math.floor(this.completionScore);
      const to = length * ((100 - trimmedNumber) / 100);
      path.style.strokeDasharray = length.toString();
      path.style.strokeDashoffset = Math.max(0, to).toString();
      const val = `
        ${this.translate(trimmedNumber)}%
      `;
      if (path.nextElementSibling) {
        path.nextElementSibling.textContent = val;
      }
    });
  }
}
