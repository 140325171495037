import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AssetsService } from '@app/shared/services/assets.service';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GeoSnackBarService } from '@app/shared/services/snack-bar/snack-bar.service';
import { ErrorService } from '@app/shared/services/error.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ImageUploaderStyle } from '@app/shared/components/form-builder/data.interface';

const ASPECT_RATIO = [1.77, 1.78, 1.79];

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent implements OnInit, OnDestroy {
  @Input() imageMinSize = 7000000;
  @Input() originalImage: string | ArrayBuffer | null = '';
  @Input() isLoading = false;
  @Input() imageContainer?: ImageUploaderStyle = 'circular';
  @Input() ratioImageValidation = false;
  @Input() sizeImageValidation = false;

  @Output() loadingEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() imageChosenEmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteImage: EventEmitter<string> = new EventEmitter<string>();
  isDeleting = false;
  imagePreview: File | undefined | null;
  errorUpload = '';
  imageSubscription$: Subscription = new Subscription();
  image: string | ArrayBuffer | null = '';

  currentCropperImage: File | undefined;
  croppedImage: ImageCroppedEvent | undefined | null;
  fileName: string = '';
  isCropperUploadButtonDisabled = true;
  croppedImageSize: { kb: number; bytes: number } = {
    kb: 0,
    bytes: 0,
  };

  constructor(
    private assetsService: AssetsService,
    private snackBarService: GeoSnackBarService,
    private errorService: ErrorService
  ) {}

  ngOnInit() {
    this.image = this.originalImage;
  }

  setImage(files: FileList) {
    this.errorUpload = '';
    const img = new Image();
    this.isDeleting = false;
    if (files?.length === 0) {
      return;
    }
    this.setLoading(true);
    const reader = new FileReader();
    const _this = this;
    reader.readAsDataURL(files?.item(0) as File);
    reader.onload = function () {
      img.src = window.URL.createObjectURL(files.item(0)!);

      img.onload = function () {
        _this.image = reader.result;
        _this.imagePreview = files?.item(0);
        _this.fileName = _this.imagePreview?.name || 'cropped-file.png';

        if (_this.sizeImageValidation) {
          if (Number(_this.imagePreview?.size) < _this.imageMinSize) {
            _this.errorUpload = `Image must be at least ${
              _this.imageMinSize / 1024
            }KB`;
            _this.setLoading(false);
            _this.image = null;
            _this.imagePreview = null;
          }
        }

        if (_this.ratioImageValidation) {
          const height = img.height;
          const width = img.width;
          const aspectRatio = Number((width / height).toFixed(2));

          if (!ASPECT_RATIO.includes(aspectRatio)) {
            _this.setLoading(false);

            _this.currentCropperImage = _this.imagePreview as File | undefined;
            _this.imagePreview = null;
          }
        }

        if (_this.imagePreview && _this.errorUpload.length === 0) {
          _this.uploadImage(_this.imagePreview);
        }
      };
    };
  }

  uploadImage(imagePreview: File) {
    this.imageSubscription$ = this.assetsService
      .uploadFile(imagePreview)
      .pipe(
        tap((imageId: string) => {
          this.setLoading(false);
          this.imageChosenEmit.emit(imageId);
        }),
        catchError((e) => {
          this.setLoading(false);
          this.errorUpload = '';

          if (this.originalImage) {
            this.image = this.originalImage;
          } else {
            this.image = null;
            this.imagePreview = null;
          }
          this.snackBarService.error({
            title: 'Error',
            message: this.errorService.getErrorMessage(e.error.message),
          });
          return EMPTY;
        })
      )
      .subscribe();
  }

  removePhoto() {
    this.isDeleting = true;
    this.image = null;
    this.imagePreview = null;
    if (this.originalImage) {
      this.deleteImage.emit('');
    }
  }

  uploadCroppedImage() {
    if (this.croppedImage?.blob) {
      this.setLoading(true);
      const file = this.createFileFromBlob(this.croppedImage.blob);
      this.uploadImage(file);
    }
  }

  private setLoading(value: boolean) {
    this.isLoading = value;
    this.loadingEmitter.emit(value);
  }

  private createFileFromBlob(blob: Blob) {
    return new File([blob], this.fileName.replace(/\.[^/.]+$/, '.png'), {
      type: blob.type,
    });
  }

  // #region <image-cropper> methods
  imageCropped(event: ImageCroppedEvent) {
    if (event.objectUrl) {
      this.croppedImage = event;
      this.croppedImageSize.bytes = this.croppedImage?.blob?.size || 1;
      this.croppedImageSize.kb = Math.round(this.croppedImageSize.bytes / 1024);

      this.isCropperUploadButtonDisabled =
        this.imageMinSize > this.croppedImageSize.bytes;
    }
  }
  // #endregion <image-cropper> methods

  ngOnDestroy() {
    this.imageSubscription$.unsubscribe();
  }
}
