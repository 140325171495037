import { ApplicationHttpClient } from '@app/helpers/custom-http-client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authUrl = '/auth';

  constructor(private http: ApplicationHttpClient) {}

  public sendPasswordResetEmail(email: string): Observable<void> {
    const url: string = this.authUrl + '/send-password-reset-email';
    return this.http.post(
      url,
      { email: email },
      {
        headers: new HttpHeaders({ 'bypass-interceptor': 'true' }),
      }
    );
  }

  public sendVerificationEmail(email: string): Observable<void> {
    const url: string = this.authUrl + '/send-verification-email';
    return this.http.post(url, { email: email });
  }
}
