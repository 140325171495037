<div
  role="none"
  class="navigation-item"
  [ngClass]="{ 'navigation-item-disabled': item.disabled }"
  [matTooltip]="item.tooltip || ''"
  (click)="toggleCollapsable()"
>
  <span class="navigation-item-title">{{ item.title }}</span>
  <mat-icon
    class="mat-icon-rtl-mirror navigation-item-arrow"
    svgIcon="dropdown-arrow"
    [class.rotate-down]="!isCollapsed"
  >
  </mat-icon>
</div>
<div class="navigation-item-children" *ngIf="!isCollapsed" @expandCollapse>
  <ng-container *ngFor="let item of item.children; trackBy: trackByFn">
    <ng-container *ngIf="item.type === navigationType.item">
      <app-navigation-item [isChild]="true" [item]="item"></app-navigation-item>
    </ng-container>
  </ng-container>
</div>
