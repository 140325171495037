import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, switchMap, take, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { FirebaseAuthService } from '@app/shared/services/firebase-auth.service';
import { GeoSnackBarService } from '@app/shared/services/snack-bar/snack-bar.service';

@Injectable()
export class FireAuthHeaderInterceptor implements HttpInterceptor {
  constructor(
    private authService: FirebaseAuthService,
    private snackBarService: GeoSnackBarService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const segments = request.url.split('/');
    const lastSegment = segments.pop();

    if (
      request.headers.get('bypass-interceptor') ||
      (request.method == 'GET' &&
        request.url.includes('assets') &&
        lastSegment?.includes('.'))
    ) {
      // Skip intercepting and pass the request directly to the next handler
      const cloned = request.clone({
        headers: new HttpHeaders({}),
      });
      return next.handle(cloned);
    }
    return this.authService.token$.pipe(
      filter((token) => !!token),
      take(1),
      switchMap((token) => {
        if (token) {
          const cloned = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
          return next.handle(cloned);
        }
        return next.handle(request);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handleUnauthorizedRequestsError(request, next);
        } else if (
          error.status === 400 &&
          error.error.message ==
            'rpc error: code = Unknown desc = Request had insufficient authentication scopes.'
        ) {
          this.snackBarService.error({
            title: 'Error',
            message: 'Connect again and grant all permissions',
          });
          return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private handleUnauthorizedRequestsError(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.authService.refreshToken().pipe(
      take(1),
      switchMap(() => {
        const token = this.authService.tokenSubject.value;
        const cloned = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
        return next.handle(cloned);
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
