import { createReducer, on } from '@ngrx/store';
import {
  setLanguage,
  setLanguages,
} from '@app/shared/store/user-settings/user-settings.actions';
import { Language } from '@app/shared/models/transifex';

export const userSettingsKey = 'userSettings';

export interface UserSettingsState {
  language: string;
  theme: string;
  loaded: boolean;
  languages: Language[];
}

export const initialState: UserSettingsState = {
  language: 'en',
  theme: 'spl',
  loaded: false,
  languages: [],
};

export const userSettingsReducer = createReducer(
  initialState,
  on(setLanguage, (state, { language }) => ({
    ...state,
    language: language,
  })),
  on(setLanguages, (state, { languages }) => ({
    ...state,
    languages: languages,
  }))
);
