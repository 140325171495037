import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as userSettingsActions from './user-settings.actions';
import { tap } from 'rxjs';
import { ZohoService } from '@app/shared/services/zoho.service';

@Injectable()
export class UserSettingsEffects {
  constructor(
    private actions$: Actions,
    private zohoService: ZohoService
  ) {}

  setLanguage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userSettingsActions.setLanguage),
        tap(() => {
          // Handle if ASAP window is already opened while you change the language
          this.zohoService.changeASAPLang();
        })
      ),
    { dispatch: false }
  );
}
