export const environment = {
  production: true,
  api: 'localhost:8080',
  backend: 'https://app.geoking.com', // test
  transifex_api: '0b78e7a23e644bedb4b510326eead9a7',
  clientUrl: 'https://app.geoking.com',
  adminUrl: 'https://app.geoking.com/admin',
  gtm: 'GTM-KNKMXCG8',
  zoho_project_id: '702765000053561069',
  zoho_org_id: '773804226',
  sentry: {
    sentryAuthToken: '06bf0743b3eb42708990524d6998d08729d1aaea9c244d88b0e8368d8c423b38',
    sentryDSN: '',
    project: '',
    org: '',
  },
  firebase: {
    projectId: 'geoking-prod-45de6',
    appId: '1:320232414176:web:b9544eb9f53769cabba985',
    storageBucket: 'geoking-prod-45de6.appspot.com',
    authDomain: 'geoking-prod-45de6.firebaseapp.com',
    messagingSenderId: '320232414176',
    apiKey: 'AIzaSyBlVSuQ1_m4gKVMh2JbTpYYT5it3db6c3Q',
  },
};
