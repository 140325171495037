<section [class.bg]="showBg" [style.background-color]="isWhite ? 'white' : ''">
  <div class="text-loader-container" *ngIf="!!textToDisplay">
    <div class="text-loader-wrapper">
      <div class="text-loader-icon"></div>
      <div class="text-loader">
        {{ textToDisplay }}
      </div>
    </div>
  </div>
  <div class="loader" *ngIf="!textToDisplay"></div>
</section>
