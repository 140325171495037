import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthFacade } from '@app/shared/store/auth/auth.facade';
import { map } from 'rxjs/operators';
import { PlanSubscription } from '@app/shared/models/user/plan-subscription';
import { PlanTypeEnum } from '@app/shared/models/payment-result';

@Injectable({
  providedIn: 'root',
})
export class PlanAccessResolver {
  constructor(
    private router: Router,
    private authFacade: AuthFacade
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PlanTypeEnum> {
    const accesses = route.data['planAccess'];
    return this.authFacade.selectCompanySubscription$.pipe(
      map((planSubscription: PlanSubscription) => {
        const haveAccess: boolean = accesses.includes(
          planSubscription.selectedPlan
        );

        if (haveAccess) {
          return planSubscription.selectedPlan;
        }

        this.router.navigate(['/no-permission']);
        return planSubscription.selectedPlan;
      })
    );
  }
}
