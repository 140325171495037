import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromUserSettings from '@app/shared/store/user-settings/user-settings.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserSettingsEffects } from '@app/shared/store/user-settings/user-settings.effect';

@NgModule({
  imports: [
    StoreModule.forFeature(
      fromUserSettings.userSettingsKey,
      fromUserSettings.userSettingsReducer
    ),
    EffectsModule.forFeature([UserSettingsEffects]),
  ],
})
export class UserSettingsStoreModule {}
