<div class="dialog-wrapper">
  <ng-container *ngIf="brands$ | async as myBrands">
    <h1>Select Brand</h1>
    <ng-container *ngFor="let brand of myBrands">
      <div
        class="card notranslate"
        (click)="selectBrand(brand)"
        aria-hidden="true"
      >
        <h2>
          {{ brand.name }}
        </h2>
      </div>
    </ng-container>
  </ng-container>
</div>
