<div class="header">
  <div class="d-flex align-items-center">
    <div class="icon" *ngIf="icon && !activateBack">
      <mat-icon class="material-icons" [svgIcon]="icon"></mat-icon>
    </div>
    <div class="back-button" *ngIf="activateBack">
      <button mat-icon-button (click)="backClicked()">
        <mat-icon class="mat-icon-rtl-mirror" svgIcon="chevron-left"></mat-icon>
      </button>
    </div>
    <div (click)="titleClicked()">
      <h1
        [ngClass]="{ title: true, notranslate: !isTitleTranslatable }"
        [innerHtml]="title"
      ></h1>
      <p *ngIf="subTitle" [innerHtml]="subTitle"></p>
    </div>
  </div>
  <button
    [disabled]="isButtonDisabled"
    [matTooltip]="toolTip"
    class="primary-button"
    *ngIf="buttonLabel"
    (click)="buttonClicked()"
  >
    <mat-icon
      class="material-icons white-icon"
      *ngIf="showPlusIcon"
      svgIcon="add"
    ></mat-icon>
    <mat-icon
      *ngIf="buttonIcon"
      class="material-icons"
      [svgIcon]="buttonIcon"
    ></mat-icon>
    <span class="mx-2">{{ buttonLabel }}</span>
  </button>
  <ng-content></ng-content>
</div>
