import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({
  name: 'datetimeTranslate',
  pure: false,
})
export class DatetimeTranslatePipe implements PipeTransform {
  transform(
    value: string | Date | undefined | null,
    args?: { format?: string; valueFormat?: string }
  ): string {
    return value !== null
      ? moment(value, args?.valueFormat).format(args?.format)
      : '';
  }
}
