import {
  Action,
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';
import * as homeReducer from '@app/store/home/home.reducer';

import { storageSync } from '@larscom/ngrx-store-storagesync';
import * as authReducer from '@app/shared/store/auth/auth.reducer';
import * as onBoardingReducer from '@app/modules/onboarding/store/reducers';
import * as userSettingsReducer from '@app/shared/store/user-settings/user-settings.reducer';
import * as brandsReducer from '@app/shared/store/brands/brands-reducer';
import * as locationReducer from '@app/modules/locations/store/locations-reducer';
import { signOut } from '@app/shared/store/auth/auth.actions';

export interface State {
  home: homeReducer.HomeState;
  auth: authReducer.AuthState;
  onBoarding: onBoardingReducer.OnBoardingState;
  userSettings: userSettingsReducer.UserSettingsState;
  brands: brandsReducer.BrandsState;
  locations: locationReducer.LocationsState;
}

export const reducers: ActionReducerMap<State> = {
  home: homeReducer.homeReducer,
  auth: authReducer.authReducer,
  onBoarding: onBoardingReducer.onBoardingReducer,
  userSettings: userSettingsReducer.userSettingsReducer,
  brands: brandsReducer.BrandsReducer,
  locations: locationReducer.LocationsReducer,
};

export function storageSyncReducer(
  reducer: ActionReducer<State>
): ActionReducer<State> {
  const metaReducer = storageSync<State>({
    features: [
      { stateKey: homeReducer.homeKey },
      { stateKey: authReducer.authKey, excludeKeys: ['subscriptionLoaded'] },
      { stateKey: onBoardingReducer.onBoardingKey },
      { stateKey: userSettingsReducer.userSettingsKey },
      { stateKey: brandsReducer.brandsKey },
      { stateKey: locationReducer.locationsKey },
    ],
    storage: window.localStorage,
  });

  return metaReducer(reducer);
}
export function logoutMetaReducer(reducer: ActionReducer<State>) {
  return function (state: State, action: Action) {
    if (action.type === signOut.type) {
      return reducer(undefined, action);
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer[] = [
  logoutMetaReducer,
  storageSyncReducer,
];
