export interface GeokTheme {
  familyFont: string;
  //borders
  buttonBorderRadius: string;
  iconButtonBorderRadius: string;
  lightThemeColor: string;
  screenBackgroundColor: string;
  cardBackgroundColor: string;
  // colors
  primaryTextColor: string;
  secondaryTextColor: string;
  //navigations
  navigationGradientOne: string;
  navigationGradientTwo: string;

  navigationTextColor: string;
  navigationTextActiveColor: string;

  navigationIconActiveColor: string;
  navigationIconActiveBgColor: string;

  //buttons
  primaryBgButton: string;
  primaryBgButtonHover: string;
  primaryTextButton: string;

  secondaryBgButton: string;
  secondaryBgButtonHover: string;
  secondaryTextButton: string;

  outlinedBgButtonHover: string;
  outlinedTextButton: string;

  textBgButtonHover: string;
  textTextButton: string;

  favicon: string;
  mainLogo: string;
  backgroundImage: string;
  smallLogo: string;
}

export interface GeokPlatformConfig {
  platformNameAr: string;
  platformNameEn: string;
  paymentGateway: PaymentGateway;
  platform: string;
}

export enum PaymentGateway {
  STRIPE = 'stripe',
  UPG = 'upg',
}

export interface GeokPlatformName {
  platformNameAr: string;
  platformNameEn: string;
}
