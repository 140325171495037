import { GeoKLocation } from '@app/shared/models/geo-k-location';

export interface SelectLocationDialogComponentData {
  selectedLocations: GeoKLocation[];
  filter?: LocationVerificationStatus;
  buttonLabel?: string;
  disableSubmitWhenEmpty?: boolean;
  singleSelection?: boolean;
  brandId?: string;
  title?: string;
  inViewMode: boolean;
  returnType: ModalReturnType;
}
export enum ModalReturnType {
  GKLOCATION,
  ID,
}
export enum LocationVerificationStatus {
  ALL_GOOD = 'all-good',
  ALL = 'all',
  IN_REVIEW = 'in-review',
  BLOCKED = 'blocked',
}
