export class AuthToken {
  public token: string;
  public expirationTime: Date;

  constructor(token: string, expirationDate: Date) {
    this.token = token;
    this.expirationTime = expirationDate;
  }

  public get isTokenExpired(): boolean {
    const expDate = new Date(new Date(this.expirationTime).toISOString());
    const now = new Date(new Date().toISOString());
    return expDate < now;
  }
}
