import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Language } from '@app/shared/models/transifex';
import { UserSettingsFacade } from '@app/shared/store/user-settings/user-settings.facade';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-transifex-language-picker',
  templateUrl: './transifex-language-picker.component.html',
  styleUrls: ['./transifex-language-picker.component.scss'],
})
export class TransifexLanguagePickerComponent implements OnInit, OnDestroy {
  language: Language = {} as Language;
  languages: Language[] = [];
  isLanguagePickerOpen = false;
  private langSubscription$: Subscription = new Subscription();

  constructor(
    private ngZone: NgZone,
    private translateService: TranslateService,
    private userSettingsFacade: UserSettingsFacade
  ) {}

  ngOnInit(): void {
    this.langSubscription$ = this.userSettingsFacade.selectLanguages$
      .pipe(
        tap((languages: Language[]) => {
          this.languages = languages;
          this.language =
            this.languages.find(
              (it: Language) => it.code === this.translateService.currentLang
            ) ?? ({} as Language);
        }),
        switchMap(() => this.translateService.onDefaultLangChange),
        tap((lang) => {
          this.language =
            this.languages.find((it: Language) => it.code === lang.lang) ??
            ({} as Language);
        })
      )
      .subscribe();
  }

  onLanguageChange(language: string) {
    this.isLanguagePickerOpen = false;
    const event = new CustomEvent('languageInternallyChanged', {
      detail: language,
    });
    window.dispatchEvent(event);
  }

  onToggleLanguagePicker() {
    this.isLanguagePickerOpen = !this.isLanguagePickerOpen;
  }

  ngOnDestroy() {
    this.langSubscription$.unsubscribe();
  }
}
