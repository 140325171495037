<ng-container *ngIf="brands$ | async as brands">
  <div class="brand-selection">
    <p>Brand:</p>
    <button class="primary-outlined-button" (click)="selectBrandClick()">
      <span
        *ngIf="(selectedBrand | async)?.name; else placeholder"
        class="notranslate"
        >{{ (selectedBrand | async)?.name }}</span
      >
      <ng-template #placeholder>
        <span>Select brand</span>
      </ng-template>
      <mat-icon svgIcon="chevron-down"></mat-icon>
    </button>
  </div>
</ng-container>
