import { UserAccessRolesEnum } from '@app/shared/models/user/user-access';
import { PlanTypeEnum } from '@app/shared/models/payment-result';
import { PaymentGateway } from '@app/configs/geokTheme';

export interface NavigationItem {
  id?: string;
  title?: string;
  tooltip?: string;
  type?: NavigationType;
  icon?: string;
  link?: string;
  active?: boolean;
  disabled?: boolean;
  children?: NavigationItem[];
  onClick?: () => void;
  access: UserAccessRolesEnum[];
  planAccess: PlanTypeEnum[];
  paymentGatewayAccess?: PaymentGateway[];
}

export enum NavigationType {
  item = 'item',
  aside = 'aside',
  collapsable = 'collapsable',
}

export enum NavigationAppearance {
  default = 'default',
  thin = 'thin',
}

export enum NavigationMode {
  over = 'over',
  side = 'side',
}

export enum NavigationPosition {
  start = 'start',
  end = 'end',
}
