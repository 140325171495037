import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { LoginUser } from '@app/shared/models/user/login.user.model';
import {
  BehaviorSubject,
  EMPTY,
  from,
  Observable,
  of,
  retry,
  switchMap,
  throwError,
} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import firebase from 'firebase/compat';
import { AuthService } from '@app/shared/services/auth.service';
import { GeoSnackBarService } from '@app/shared/services/snack-bar/snack-bar.service';
import IdTokenResult = firebase.auth.IdTokenResult;
import { ErrorService } from './error.service';
import { resetObj } from '@app/shared/models/user/user';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  tokenSubject = new BehaviorSubject<string | null>(null);
  token$: Observable<string | null> = this.tokenSubject.asObservable();

  constructor(
    private firebaseAuth: AngularFireAuth,
    private authService: AuthService,
    private snackBarService: GeoSnackBarService,
    private errorService: ErrorService
  ) {
    this.refreshToken().subscribe();
  }

  async signUpWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<boolean> {
    const result = await this.firebaseAuth.createUserWithEmailAndPassword(
      email,
      password
    );
    if (result.user) {
      await this.sendEmailVerification();
      return true;
    }
    return false;
  }

  async sendEmailVerification(): Promise<void> {
    const user = await this.firebaseAuth.currentUser;
    if (user?.email) {
      this.authService
        .sendVerificationEmail(user.email)
        .pipe(
          tap(() =>
            this.snackBarService.success({
              title: 'email sent',
            })
          ),
          retry({ count: 2, delay: 2000 }),
          catchError((e) => {
            this.snackBarService.error({
              title: 'Error',
              message: this.errorService.getErrorMessage(e.error.message),
            });
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  async loginWithCustomToken(token: string) {
    await this.firebaseAuth
      .signInWithCustomToken(token)
      .catch(function (error) {
        const errorCode = error.code;
        console.error(errorCode);
      });
  }

  async loginWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<LoginUser> {
    const result = await this.firebaseAuth.signInWithEmailAndPassword(
      email,
      password
    );
    if (!result.user?.emailVerified) {
      setTimeout(async () => {
        await this.sendEmailVerification();
      }, 8000);
    }
    return {
      email: result.user?.email ?? '',
      displayName: result.user?.displayName ?? '',
      isVerified: result.user?.emailVerified ?? false,
    } as LoginUser;
  }

  requestResetPassword(email: string): Observable<void> {
    return this.authService.sendPasswordResetEmail(email);
  }

  verifyPasswordResetCode(code: string): Observable<string> {
    return from(this.firebaseAuth.verifyPasswordResetCode(code));
  }

  confirmPasswordReset(code: string, password: string): Observable<void> {
    return from(this.firebaseAuth.confirmPasswordReset(code, password));
  }

  async signOut(): Promise<void> {
    await this.firebaseAuth.signOut().then(() => {
      this.tokenSubject.next(null);
    });
  }

  applyActionCode(code: string): Observable<void> {
    return from(this.firebaseAuth.applyActionCode(code));
  }

  getActionCodeData(code: string): Observable<string> {
    return from(this.firebaseAuth.checkActionCode(code)).pipe(
      map((it) => {
        return it.data.email ?? '';
      })
    );
  }

  reloadUser(): Observable<void> {
    return this.firebaseAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return user?.reload();
        }
        return EMPTY;
      })
    );
  }

  getToken(refresh = false): Observable<IdTokenResult> {
    return this.firebaseAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return from(user.getIdTokenResult(refresh));
        }
        return EMPTY;
      })
    );
  }

  refreshToken(): Observable<string> {
    return this.getToken(true).pipe(
      switchMap((it: IdTokenResult) => {
        this.tokenSubject.next(it.token);
        return it.token;
      }),
      catchError(() => {
        this.tokenSubject.next(null);
        return EMPTY;
      })
    );
  }

  isLoggedIn(): Observable<boolean> {
    return this.firebaseAuth.user.pipe(map((it) => it?.emailVerified ?? false));
  }

  getFirebaseErrorMessage(code: string): string {
    console.log(code);
    switch (code) {
      case 'auth/weak-password': {
        return 'Your password is weak';
      }
      case 'auth/email-already-in-use': {
        return 'This email is already in use';
      }
      case 'auth/invalid-email': {
        return 'Email entered is not valid';
      }
      case 'auth/operation-not-allowed': {
        return 'Operation is not allowed';
      }
      case 'auth/network-request-failed': {
        return 'failed to make request, try again later';
      }
      case 'auth/user-not-found': {
        return 'No account associated with this email address';
      }
      case 'auth/wrong-password':
      case 'auth/invalid-credential': {
        return 'Invalid email or password';
      }
      case 'user_is_deactivated': {
        return 'User is deactivated';
      }
      case 'auth/too-many-requests': {
        return 'Too many requests made, try again later';
      }
      default: {
        return 'Something went wrong';
      }
    }
  }
}
