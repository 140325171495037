import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  brandsKey,
  BrandsState,
} from '@app/shared/store/brands/brands-reducer';
import { Brand } from '@app/shared/models/brand';

export const selectBrandsState = createFeatureSelector<BrandsState>(brandsKey);
export const selectBrands = createSelector(
  selectBrandsState,
  (it: BrandsState) => it.brands
);
export const selectSelectedBrand = createSelector(
  selectBrandsState,
  (it: BrandsState) => it.selectedBrand
);
export const selectSelectedBrandSocialIntegrationStatus = createSelector(
  selectBrandsState,
  (it: BrandsState) => it.socialIntegrationStatuses
);

export const selectBrandById = (id: string) =>
  createSelector(
    selectBrandsState,
    (it) => it.brands.find((it) => it.id === id) ?? ({} as Brand)
  );
