import { createReducer, on } from '@ngrx/store';
import {
  setIsLoggedInByPlatformAdmin,
  setIsOnBoarding,
  setSideBarVisibility,
} from '@app/store/home/home.actions';

export const homeKey = 'home';

export interface HomeState {
  geoSidebarVisible: boolean;
  isOnBoardingStage: boolean;
  isLoggedInByPlatformAdmin: boolean;
}

export const initialState: HomeState = {
  geoSidebarVisible: false,
  isOnBoardingStage: false,
  isLoggedInByPlatformAdmin: false,
};

export const homeReducer = createReducer(
  initialState,
  on(setSideBarVisibility, (state: HomeState, { visible }) => ({
    ...state,
    geoSidebarVisible: visible,
  })),
  on(setIsOnBoarding, (state: HomeState, { isOnBoarding }) => ({
    ...state,
    isOnBoardingStage: isOnBoarding,
  })),
  on(
    setIsLoggedInByPlatformAdmin,
    (state: HomeState, { isLoggedInByPlatformAdmin }) => ({
      ...state,
      isLoggedInByPlatformAdmin: isLoggedInByPlatformAdmin,
    })
  )
);
